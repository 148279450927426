import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
import Cloud from "@material-ui/icons/Cloud";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Storage from "@material-ui/icons/Storage";
import DeveloperMode from "@material-ui/icons/DeveloperMode";
import MobileFriendly from "@material-ui/icons/MobileFriendly";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>
                        <i className="fas fa-bars" />&nbsp;
                        Our Services
                    </h2>
                    <h5 className={classes.description}>
                        Combinamos la ingeniería, la innovación y el diseño con un abordaje único que facilita la reinvención de las empresas.
                    </h5>
                </GridItem>
            </GridContainer>

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Cloud Native"
              description="Trabajamos con plataformas en la nube y tenemos la experiencia y los frameworks necesarios para ayudarte con tu estrategia de adopción de la nube, la transformación de aplicaciones, el plan de recuperación ante desastres y el soporte continuo."
              icon={Cloud}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Security"
              description="Creamos productos de software seguros utilizando las mejores prácticas reconocidas por la industria. Diseñamos aplicaciones seguras desde el principio, integrando la seguridad en el diseño de la arquitectura y la infraestructura."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="DevOps"
              description="Facilitamos la integración y entrega continuas, centrándonos en la calidad y la transparencia. Nos enfocamos en ayudar a los equipos tecnológicos a mejorar su ciclo general del producto."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>

        <GridContainer justify="center" >
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Serverless"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={DeveloperMode}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Microservices"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Storage}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Mobile"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={MobileFriendly}
              iconColor="warning"
              vertical
            />
          </GridItem>

        </GridContainer>

        <GridContainer justify="center" >

        </GridContainer>

      </div>
    </div>
  );
}
